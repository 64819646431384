const utils = {
  permission_set: {
    ADM: [
      "home",
      "login",
      "teacher",
      "student",
      "class-schedule",
      "today-slot",
      "enroll-staff",
      "staff-list",
      "in-active-staff",
      "my-details",
      "staff-self-service",
      "class-details",
      "subject-assign",
      "time-slot",
      "view-slot",
      "time-table",
      "view-syllabus",
      "lesson-update",
      "generate-qr-code",
      "print-preview",
      "shared-lesson",
      "create-message",
      "message-history",
      "complain-form",
      "staff-reports",
      "planner-reports",
      "time-table-reports",
      "chapters-units-reports",
      "lesson-reports",
    ],
    EMP: [
      "home",
      "login",
      "class-schedule",
      "today-slot",
      "staff-list",
      "my-details",
      "staff-self-service",
      "class-details",
      "subject-assign",
      "view-slot",
      "time-table",
      "view-syllabus",
      "lesson-update",
      "generate-qr-code",
      "print-preview",
      "shared-lesson",
      "complain-form",
      "message-history",
    ],
    SUP: [
      "home",
      "login",
      "add-school",
      "school-list",
      "settings",
      "create-chapter",
      "view-syllabus",
    ],
  },
  baseURL: `http://${window.location.hostname}/api/`,
  getUserInfo: (key = "", obj = "") => {
    let user_details = obj == "" ? localStorage.getItem("user_details") : obj;
    let value = "";
    if (user_details != "" && user_details != null) {
      let response = JSON.parse(atob(user_details));
      value =
        key == "staff_name"
          ? `${response["first_name"]} ${response["last_name"]}`
          : response[key];
    }
    return value;
  },
  getUserId: (obj = "") => {
    let user_details = obj == "" ? localStorage.getItem("user_details") : obj;
    let userID = 0;
    if (user_details != "" && user_details != null) {
      let response = JSON.parse(atob(user_details));
      userID = response["id"] || 0;
    }
    return isNaN(userID) ? 0 : userID;
  },
  getUserPhoto: (obj = "") => {
    let user_details = obj == "" ? localStorage.getItem("user_details") : obj;
    let photo_path = localStorage.getItem("photo_path") || "";
    if (user_details != "" && user_details != null && photo_path == "") {
      let response = JSON.parse(atob(user_details));
      photo_path = response["photo_path"] || "";
    }
    return photo_path;
  },
  getUserType: (obj = "") => {
    let user_details = obj == "" ? localStorage.getItem("user_details") : obj;
    let user_type = "";
    if (user_details != "" && user_details != null) {
      let response = JSON.parse(atob(user_details));
      user_type = response["user_type"] || "";
    }
    return user_type;
  },
  getUserDetails: (obj = "") => {
    let user_details = obj == "" ? localStorage.getItem("user_details") : obj;
    return user_details == "" || user_details == null
      ? {}
      : JSON.parse(atob(user_details));
  },
  getList: (type = "") => {
    let response = [];
    let listDetails = localStorage.getItem("listDetails") || "";
    if (listDetails != "") {
      listDetails = JSON.parse(listDetails);
      if (listDetails[type]) response = [...listDetails[type]];
    }
    return response;
  },
  getPermissionSet() {
    const permission_set = this.permission_set || {};
    return permission_set[this.getUserType()] || [];
  },
  getSubjectName(id = 0) {
    let name = [];
    let listDetails = localStorage.getItem("listDetails") || "";
    if (listDetails != "") {
      listDetails = JSON.parse(listDetails);
      if (listDetails["subject_list"]) {
        name = listDetails["subject_list"].filter((el) => el.id == id);
      }
    }
    return name.length > 0 ? name[0]["subject_name"] : "";
  },
  getClassName(id = 0) {
    let name = [];
    let listDetails = localStorage.getItem("listDetails") || "";
    if (listDetails != "") {
      listDetails = JSON.parse(listDetails);
      if (listDetails["class_list"]) {
        name = listDetails["class_list"].filter((el) => el.id == id);
      }
    }
    return name.length > 0 ? name[0]["name"] : "";
  },
  getClassType(id = 0) {
    let name = [];
    let listDetails = localStorage.getItem("listDetails") || "";
    if (listDetails != "") {
      listDetails = JSON.parse(listDetails);
      if (listDetails["class_type"]) {
        name = listDetails["class_type"].filter((el) => el.id == id);
      }
    }
    return name.length > 0 ? name[0]["type"] : "";
  },
  getUnivList() {
    let name = [];
    let listDetails = localStorage.getItem("listDetails") || "";
    if (listDetails != "") {
      listDetails = JSON.parse(listDetails);
      if (listDetails["university_list"]) {
        name = listDetails["university_list"];
      }
    }
    return name;
  },
};

export default utils;
