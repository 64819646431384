import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "../lang/texts";
let currentlanguage = localStorage.getItem("language") || "en";
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: currentlanguage, // set locale,
  messages: messages,
});
export default i18n;
