const navList = [
  {
    name: "Dashboard",
    permission: ["ADM", "EMP"],
    options: [
      {
        name: "Student",
        icon: "mdi-face-man",
        route: "student",
        permission: ["TEST"],
      },
      {
        name: "Class Schedule",
        icon: "mdi-clipboard-clock-outline",
        route: "class-schedule",
        permission: ["ADM", "EMP"],
      },
      {
        name: "Today Slot",
        icon: "mdi-calendar-blank",
        route: "today-slot",
        permission: ["ADM", "EMP"],
      },
    ],
  },
  {
    name: "Schools",
    permission: ["SUP"],
    options: [
      {
        name: "Add",
        icon: "mdi-bank",
        route: "add-school",
        permission: ["SUP"],
      },
      {
        name: "View List",
        icon: "mdi-view-carousel-outline",
        route: "school-list",
        permission: ["SUP"],
      },
    ],
  },
  {
    name: "Staff",
    permission: ["ADM", "EMP"],
    options: [
      {
        name: "Enroll",
        icon: "mdi-plus",
        route: "enroll-staff",
        permission: ["ADM"],
      },
      {
        name: "View List",
        icon: "mdi-list-box",
        route: "staff-list",
        permission: ["ADM", "EMP"],
      },
      {
        name: "My Details",
        icon: "mdi-card-account-details",
        route: "my-details",
        permission: [],
      },
      {
        name: "Self Service",
        icon: "mdi-account-edit",
        route: "staff-self-service",
        permission: ["ADM", "EMP"],
      },
      {
        name: "InActive List",
        icon: "mdi-list-box",
        route: "in-active-staff",
        permission: ["ADM"],
      },
    ],
  },
  {
    name: "Time",
    permission: ["ADM"],
    options: [
      {
        name: "Time Slot",
        icon: "mdi-clock",
        route: "time-slot",
        permission: ["ADM"],
      },
      {
        name: "View Slots",
        icon: "mdi-format-list-checkbox",
        route: "view-slot",
        permission: ["EMP", "ADM"],
      },
    ],
  },
  {
    name: "Planner",
    permission: ["ADM", "EMP"],
    options: [
      {
        name: "Class Details",
        icon: "mdi-list-box",
        route: "class-details",
        permission: ["ADM", "EMP"],
      },
      {
        name: "Subject Assign",
        icon: "mdi-book",
        route: "subject-assign",
        permission: ["ADM", "EMP"],
      },
    ],
  },

  {
    name: "Chapter",
    permission: ["ADM", "EMP", "SUP"],
    options: [
      {
        name: "Units & contents",
        icon: "mdi-folder-table-outline",
        route: "create-chapter",
        permission: ["SUP"],
      },
      {
        name: "View syllabus",
        icon: "mdi-folder-table-outline",
        route: "view-syllabus",
        permission: ["ADM", "EMP", "SUP"],
      },
    ],
  },
  {
    name: "Update",
    permission: ["ADM", "EMP"],
    options: [
      {
        name: "Lesson Update",
        icon: "mdi-update",
        route: "lesson-update",
        permission: ["ADM", "EMP"],
      },
    ],
  },
  {
    name: "Messages",
    permission: ["ADM", "EMP"],
    options: [
      {
        name: "Create Msg",
        icon: "mdi-message-text-outline",
        route: "create-message",
        permission: ["ADM"],
      },
      {
        name: "View History",
        icon: "mdi-message-text-clock-outline",
        route: "message-history",
        permission: ["ADM", "EMP"],
      },
    ],
  },
  {
    name: "Complain",
    permission: ["ADM", "EMP"],
    options: [
      {
        name: "Complain Form",
        icon: "mdi-update",
        route: "complain-form",
        permission: ["ADM", "EMP"],
      },
    ],
  },
  {
    name: "Reports",
    permission: ["NAN"],
    options: [
      {
        name: "Staff",
        icon: "mdi-home-analytics",
        route: "staff-reports",
        permission: ["ADM"],
      },
      {
        name: "Planner",
        icon: "mdi-file-chart",
        route: "planner-reports",
        permission: ["ADM"],
      },
      {
        name: "Time-table",
        icon: "mdi-chart-donut-variant",
        route: "time-table-reports",
        permission: ["ADM"],
      },
      {
        name: "Chapter & Units",
        icon: "mdi-text-long",
        route: "chapters-units-reports",
        permission: ["ADM"],
      },
      {
        name: "Lessons",
        icon: "mdi-table",
        route: "lesson-reports",
        permission: ["ADM"],
      },
    ],
  },
];

export default navList;
