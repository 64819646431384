import { createStore } from "vuex";
const store = createStore({
  state: {
    activeSymbols: [],
    graphList: {},
  },
  mutations: {
    add(state, payload) {
      return (state.activeSymbols = payload);
    },
    addGraph(state, payload) {
      return (state.graphList[payload.key] = payload.value);
    },
  },
  actions: {
    add(context, payload) {
      context.commit("add", payload);
    },
    addGraph(context, payload) {
      context.commit("addGraph", payload);
    },
  },
  getters: {
    activeSymbols(state) {
      return state.activeSymbols;
    },
    getGraphData: (state) => (id) => {
      return state.graphList[id] || [];
    },
  },
});
export default store;
