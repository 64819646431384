import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import router from "./router";
import VueApexCharts from "vue-apexcharts";
import i18n from "./plugins/locale";
import store from "./plugins/store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Utils from "./utils/utils";
import axiosApiInstance from "./plugins/http";
import SelectDate from "@/components/molecules/SelectDate";
import TabsList from "@/components/molecules/TabsList";
import moment from "moment";
import VueClipboards from "vue-clipboards";
const EventBus = new Vue();
Vue.use(VueApexCharts);
Vue.use(Loading);
Vue.use(VueClipboards);

Vue.config.productionTip = false;
Vue.prototype.$http = axiosApiInstance;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.baseURL = process.env.VUE_APP_API;
Vue.prototype.filePath = process.env.VUE_APP_FILE_PATH;
Vue.prototype.$store = store;
Vue.prototype.$utils = Utils;
Vue.prototype.$moment = moment;
Vue.use(VueRouter);
Vue.component("apex-chart", VueApexCharts);
Vue.component("select-date", SelectDate);
Vue.component("tabs-list", TabsList);
String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.split(search).join(replacement);
};
new Vue({
  vuetify,
  router,
  i18n,
  render: (h) => h(App),
  theme: {
    themes: {
      light: {
        primary: "#3f51b5",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
      },
    },
  },
}).$mount("#app");
