<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        outlined
        :rules="[rules.required]"
        v-model="date"
        :label="dateLabel"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable :max="maxDate">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="saveDate"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dateLabel: {
      type: String,
      default: "",
      required: false,
    },
    dateValue: {
      type: String,
      default: "",
      required: false,
    },
    maxDate: {
      Type: String,
      default: "2030-12-31",
      required: false,
    },
  },
  data: () => ({
    date: "",
    modal: false,
    rules: {
      required: (value) => !!value || "Date should not be empty",
    },
  }),
  created() {
    this.date =
      this.dateValue != ""
        ? this.$moment(this.dateValue).format("YYYY-MM-DD")
        : "";
  },
  methods: {
    saveDate() {
      this.$refs.dialog.save(this.date);
      this.$emit("selectedDate", this.date);
    },
  },
};
</script>
