import Vue from "vue";
import VueRouter from "vue-router";
import Utils from "../utils/utils";

Vue.use(VueRouter);

const routes = [
  {
    path: "/schools",
    name: "schools",
    component: () => import("@/components/schools/SchoolsComp"),
  },
  {
    path: "/syllabus",
    name: "syllabus",
    component: () => import("@/components/syllabus/SyllabusComp"),
  },
  {
    path: "/staff",
    name: "staff",
    component: () => import("@/components/staff/StaffComp"),
    props: true,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/login/LoginPage.vue"),
  },
  {
    path: "/planner",
    name: "planner",
    component: () => import("@/components/planner/PlannerComp.vue"),
  },
  {
    path: "/update",
    name: "update",
    component: () => import("@/components/update/UpdateComp.vue"),
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/components/message/MessageComp.vue"),
  },
  {
    path: "/",
    name: "home",
    beforeEnter: (to, from, next) => {
      if (Utils.getUserId() == 0) {
        next({ name: "login" });
      } else if (Utils.getUserId() != 0) {
        Utils.getUserType() == "SUP"
          ? next({ name: "schools" })
          : next({ name: "syllabus" });
      }
    },
  },
  {
    path: "/print-preview/:id/:school_code",
    name: "print-preview",
    component: () => import("@/components/lesson/PrintPreview"),
    props: true,
  },
  {
    path: "/generate-qr-code",
    name: "generate-qr-code",
    component: () => import("@/components/lesson/GenerateQRCode"),
    props: true,
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name == "print-preview") {
    next();
  } else {
    const user_id = Utils.getUserId();
    if (user_id == 0 && to.name != "login") {
      localStorage.clear();
      next({ name: "login" });
    } else {
      next();
    }
  }
});
export default router;
