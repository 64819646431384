<template>
  <v-tabs v-model="tab">
    <template v-for="(list, index) in this.tabsList">
      <v-tab
        :class="index == tab ? 'b-color' : ''"
        v-if="list.permission.includes(userType)"
        :key="list.key"
        ><span :class="index == tab ? 'w-color' : 'bl-color'">{{
          list.name
        }}</span></v-tab
      ></template
    >
  </v-tabs>
</template>
  
  <script>
export default {
  props: ["page"],
  data: () => ({
    tab: "",
    tabsList: [],
    userType: "",
  }),
  mounted() {
    this.userType = this.$utils.getUserType();
    this.setTabsList();
  },
  watch: {
    tab(val) {
      this.$emit("tab-value", val);
    },
  },
  methods: {
    setTabsList() {
      this.tabsList = [];
      switch (this.page) {
        case "schools":
          this.tabsList.push(
            {
              name: "View",
              key: "view-list",
              permission: ["SUP"],
            },
            {
              name: "Add",
              key: "add-school",
              permission: ["SUP"],
            }
          );
          break;
        case "syllabus":
          this.tabsList.push(
            {
              name: "Add",
              key: "add-chapter",
              permission: ["SUP"],
            },
            {
              name: "View",
              key: "view-list",
              permission: ["SUP", "ADM", "EMP"],
            }
          );
          break;
        case "staff":
          this.tabsList.push(
            {
              name: "View List",
              key: "view-list",
              permission: ["ADM", "EMP"],
            },
            {
              name: "Enroll",
              key: "enroll",
              permission: ["ADM"],
            },
            {
              name: "Self Service",
              key: "self-service",
              permission: ["ADM", "EMP"],
            }
          );
          break;
        case "update":
          this.tabsList.push({
            name: "Lesson Update",
            key: "lesson-update",
            permission: ["ADM", "EMP"],
          });
          break;
        case "planner":
          this.tabsList.push(
            {
              name: "Class Details",
              key: "class-details",
              permission: ["ADM", "EMP"],
            },
            {
              name: "Subject Assign",
              key: "subject-assign",
              permission: ["ADM", "EMP"],
            },
            {
              name: "Time Slot",
              key: "time-slot",
              permission: ["ADM"],
            },
            {
              name: "View Slots",
              key: "view-slot",
              permission: ["ADM", "EMP"],
            }
          );
          break;
        case "message":
          this.tabsList.push(
            {
              name: "Message History",
              key: "message-history",
              permission: ["ADM", "EMP"],
            },
            {
              name: "Create Message",
              key: "create-message",
              permission: ["ADM"],
            },
            {
              name: "Complain Form",
              key: "complain-form",
              permission: ["ADM", "EMP"],
            }
          );
          break;
      }
      this.tabsList = this.tabsList.filter((e) =>
        e.permission.includes(this.$utils.getUserType())
      );
    },
  },
};
</script>
  <style stype="scss">
.b-color {
  background-color: #1f3370;
  color: white;
}
.w-color {
  color: white;
}
.bl-color {
  color: #1f3370;
}
</style>