import axios from "axios";
const axiosApiInstance = axios.create();
import utils from "../utils/utils";
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  (config) => {
    config.headers = Object.assign(
      {
        authtoken: localStorage.getItem("auth_token"),
        schoolcode: localStorage.getItem("school_code"),
        userid: utils.getUserId(),
        user_type: utils.getUserType(),
      },
      config.headers
    );
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
