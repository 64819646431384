import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1f3370", // Change this to your desired primary color
        warning: "#ffb92e",
      },
    },
    customClasses: {
      textTransform: "no-text-transform",
    },
  },
});
