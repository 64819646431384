const lang = {
  en: {
    reflectionMessage: {
      tamil: `........... / ........... மாணவர்கள் பாட நோக்கத்தை அடைந்தனர்; வளப்படுத்துதல் / திடப்படுத்துதல் பயிற்சிகள் வழங்கப்பட்டது.

      ............ / ............ மாணவர்கள் பாட நோக்கத்தை அடையவில்லை; ஆசிரியர் வழிக்காட்டலுடன் குறைநீக்கல் பயிற்சி வழங்கப்பட்டது.

இன்றைய கற்றல் கற்பித்தல் நடவடிக்கை ஒத்திவைக்கப்பட்டது. காரணம்
□	கூட்டம் / பணிமனை 
□	பள்ளி நிகழ்ச்சி 
□	ஆசிரியர் சிறப்பு விடுப்பு  /  மருத்துவ விடுப்பு / பேரிடர் விடுப்பு  
□	  மற்றவை .......................................................................................

கற்றல் கற்பித்தல் நடவடிக்கை அடுத்த பாட வேளைக்குக் கொண்டு செல்லப்படுகிறது.

ஆசிரியர் சுய மதிப்பீடு
`,
      english: `enrichment / reinforcement exercises. 

........... / ........... pupils were not able to master the learning objectives and were given remedial activity with teacher guidance. 

Teaching and learning activities could not be carried out today due to ………………………………….
□ Meeting / Course / Briefing 
□ School Programme
□ Special Rest Leave / Sick Leave (MC) / Leave Without Record / Disaster Leave 
□ Others : ....................................................................................... 

Teaching and learning activities will be carried out during the next learning session. 

Teacher’s Self-Assessment
`,
      malay: `........... / ........... orang murid dapat menguasai objektif pembelajaran dan diberi latihan pengayaan / pengukuhan.

     ........... / ........... orang murid tidak dapat mencapai objektif pembelajaran dan diberi latihan pemulihan dengan bimbingan guru.

Aktiviti  pembelajaran dan pemudahcaraan ditangguhkan kerana

□	Mesyuarat / Bengkel / Taklimat 
□	Program Sekolah 
□	Cuti Rehat Khas / Cuti Sakit / Cuti Tanpa Rekod /  Cuti bencana 
□	Lain –Lain : .......................................................................................

Proses pengajaran dan pembelajaran akan diteruskan pada sesi pembelajaran akan datang.

Penilaian Kendiri Guru
`,
    },
    errors: {
      went_wrong: "Something went wrong. Please try again",
      403: "school code not exist",
    },
    message: {
      header: "EN Dashboard",
    },
    common: {
      end_date: " End Date",
      no_schedule: " No Schedule",
    },
    chapter_list: {
      unit_name: "Unit Name",
      content_name: "Content Name",
      learning_standard: "Content Standard",
      content_standard: "Learning Standard",
    },
    enroll_staff: {
      first_name: "First Name",
      last_name: "Last Name",
      contact_number: "Contact Number",
      gender: "Gender",
      religion: "Religion",
      date_of_joining: "Date of Joining",
      user_type: "User Type",
      race: "Race",
      date_of_birth: "Date of Birth",
      email: "Email",
      category: "Category",
      enroll_new_staff: "Enroll New Staff",
      reset: "Reset Form",
      add: "Add Staff",
    },
    class_schedule: {
      weekly_time_table: "Weekly Time Table",
      class_type: "Class Type",
      class_name: "Class Name",
    },
    today_slot: {
      today_time_table: "Today Time Table",
    },
    staff_list: {
      search: "Search",
      staff_list: "Staff List",
    },
    time_slot: {
      add_time: "Add Time",
      start: "Start Time",
      end: "End Time",
      save: "Add",
    },
    view_slots: {
      time_slot: "Time Slot",
      time_slot_details: "Time Slot Details",
      add: "Add",
      action: "Action",
      rows_per_page: "Rows per page",
      start: "Start Time",
      end: "End Time",
      active: "Active",
    },
    class_details: {
      class_details: "Class Details",
      class_type: "Class Type",
      class_name: "Class Name",
      assign_subject: "Assign Subject",
      no_of_students: "No of Students",
      add_class: "Add Class",
      rows_per_page: "Rows per page",
      action: "Action",
      save: "Save",
      close: "Close",
    },
    subject_assign: {
      subject: "Subject",
      assignation_details: "ASSIGNATION DETAILS",
      assign_subject: "Assign Subject",
      add_new_subject: "Add New Subject",
      select_subject: "Select Subject",
      select_staff: "Select Staff",
      action: "Action",
      no_of_students: "No of Students",
      class_type: "Class Type",
      class_name: "Class Name",
      rows_per_page: "Rows per page",
      save: "Save",
      close: "Close",
      subject_name: "Subject Name",
      end_date: "End Date",
      add_time_table: "Add Time Table",
      week_day: "Week Day",
      schedule: "Schedule",
      assign: "Assign",
      staff_name: "Staff Name",
      start_date: "Start Date",
      time_slot: "Time Slot",
    },
    lesson_update: {
      class_type: "Class Type",
      lesson_update: "Lesson Update",
      self_assign: "Self Assign",
      class_name: "Class Name",
      time_slot: "Time Slot",
      start_date: "Start Date",
      end_date: "End Date",
      actions: "Action",
      name: "Name",
      date: "Date",
      subject: "Subject",
      day: "Day",
      status: "Status",
      first_name: "First Name",
      to_date: "To Date",
      from_date: "From Date",
      search: "Search",
      reset_filter: "Reset Filter",
      download_report: "Download report",
      unit_name: "Unit",
      content_name: "Content",
      unit: "Unit",
      content: "Content",
      learning_standard: "Content Standard",
      content_standard: "Learning Standard",
      activity: "Activity",
      objective: "Objective",
      save: "Save",
      update: "Update",
      view: "View",
      update_reflection: "Update Reflection",
      refer_back: "Refer Back",
      referred_back: "Referred Back",
      approve: "Approve",
      reflection: "Reflection",
      approved: "Approved",
      close: "Close",
      pending: "Pending",
      refer_back_approve_comments: "Refer Back / Approve Comments",
      approve_comments: "Approve Comments",
    },
    create_message: {
      message: "Message",
      all_staffs: "All Staffs",
      select_staff: "Select Staff",
      start_date: "Start Date",
      end_date: "End Date",
      create_new_message: "Create New Message",
    },
    message_history: {
      start_date: "Start Date",
      end_date: "End Date",
      message: "Message",
      actions: "Action",
      messages: "Messages",
      rows_per_page: "Rows per page",
    },
    view_syllabus: {
      view_syllabus: "VIEW SYLLABUS",
      class_type: "Class Type",
      select_subject: "Select Subject",
      view: "View",
    },
    complain_form: {
      school_name: "School Name",
      school_code: "School Code",
      staff_name: "Staff Name",
      phone_number: "Phone Number",
      complain: "Complain",
      send_complaint: "Send Complaint",
      submit_complaint: "Submit Complaint",
    },
    self_service: {
      staff_self_service: "Staff Self Service",
      personal_details: "Personal Details",
      education_details: "Education Details",
      name: "Name",
      email: "Email",
      gender: "Gender",
      user_type: "User Type",
      no_rs: "No R/S",
      ic_number: "I/C Number",
      mobile_number: "Mobile Number",
      category: "Category",
      permanent_address: "Permanent Address",
      current_address: "Current Address",
      spouse_name: "Spouse Name",
      spouse_phone_number: "Spouse Phone Number",
      spouse_job_details: "Spouse Job Details",
      upload_photo: "Upload Photo",
      first_employment_joining_date: "First Employment Joining Date",
      current_employment_joining_date: "Current Employment Joining Date",
      save: "Save",
      course: "Course",
      major: "Major",
      university: "University",
      year: "Year",
      actions: "Actions",
      no_data_availble: "No data available",
      rows_per_page: "Rows per page",
      add: "Add",
      joining_date: "Joining Date",
    },
  },
  my: {
    message: {
      header: "Malay Dashboard",
    },
    common: {
      end_date: " End Date",
      no_schedule: " No Schedule",
    },
    chapter_list: {
      unit_name: "Unit Name",
      content_name: "Content Name",
      learning_standard: "Content Standard",
      content_standard: "Learning Standard",
    },
    //---------------------------
    enroll_staff: {
      first_name: "Nama Pertama",
      last_name: "Nama Keluarga",
      contact_number: "Nombor Telefon",
      gender: "Jantina",
      religion: "Agama",
      date_of_joining: "Tarikh Lantikan",
      user_type: "Jenis Pengguna",
      race: "Bangsa",
      date_of_birth: "Tarikh Lahir",
      email: "Email",
      category: "Kategori",
      enroll_new_staff: "Daftar Pengguna Baru",
      reset: "Tetapkan Semula",
      add: "Tambah",
    },
    class_schedule: {
      weekly_time_table: "Jadual Waktu Mingguan",
      class_type: "Jenis Kelas",
      class_name: "Nama Kelas",
    },
    today_slot: {
      today_time_table: "Jadual Waktu Harian",
    },
    staff_list: {
      search: "Cari",
      staff_list: "Senarai Pengguna",
    },
    Time_slot: {
      add_time: "Waktu Masa ",
      start: "Waktu Mula",
      end: "Waktu Tamat",
      save: "Simpan",
    },
    view_slots: {
      time_slot: "Slot Masa",
      time_slot_details: "Maklumat Waktu",
      add: "Tambah",
      action: "Aktiviti",
      rows_per_page: "Halaman",
      start: "Waktu Mula",
      end: "Waktu Tamat",
      active: "aktif",
    },
    class_details: {
      class_details: "BUTIRAN KELAS",
      class_type: "Jenis Kelas",
      class_name: "Nama Kelas",
      assign_subject: "Maklumat Subjek",
      no_of_students: "Jumlah Murid",
      add_class: "Tambah Kelas",
      action: "Aktiviti",
      rows_per_page: "Halaman",
      save: "Simpan",
      close: "Dekat",
    },
    subject_assign: {
      subject: "Subjek",
      assignation_details: "Butiran Tugasan",
      assign_subject: "Maklumat Subjek",
      add_new_subject: "Tambah Subjek",
      select_subject: "Pilihan Subjek",
      select_staff: "Maklumat Pengguna",
      action: "Aktiviti",
      no_of_students: "Jumlah Murid",
      class_type: "Jenis Kelas",
      class_name: "Nama Kelas",
      rows_per_page: "Halaman",
      save: "Simpan",
      close: "Dekat",
      subject_name: "Subjek nama",
      end_date: "Tarikh Tamat",
      add_time_table: "Tambah Jadual Waktu",
      week_day: "hari minggu",
      schedule: "jadual",
      assign: "Maklumat",
      staff_name: "Nama Pengguna",
      start_date: "Tarikh Mula",
      time_slot: "Slot Masa",
    },
    lesson_update: {
      class_type: "Jenis Kelas",
      lesson_update: "LESSON UPDATE",
      self_assign: "Tugasan Perbadi",
      class_name: "Nama Kelas",
      time_slot: "Slot Waktu",
      start_date: "Tarikh Mula",
      end_date: "Tarikh Tamat",
      actions: "tindakan",
      name: "Nama",
      date: "Tarikh",
      subject: "Subjek",
      day: "Hari",
      status: "Status",
      search: "Cari",
      first_name: "Nama Pertama",
      to_date: "Sehingga Kini",
      from_date: "Dari tarikh",
      reset_filter: "TETAP SEMULA PENAPIS",

      download_report: "muat turun laporan",
      unit: "Unit",
      content: "kandungan",
      leaning_standard: "standard pembelajaran",
      content_standard: "standard kandungan",
      activity: "Aktiviti",
      objective: "objektif",
      save: "JIMAT",
      close: "DEKAT",
      update: "KEMAS KINI",
      view: "PANDANGAN",
      update_reflection: "KEMAS KINI REFLEKSI",
      refer_back: "MERUJUK KEMBALI",
      referred_back: "DIRUJUK KEMBALI",
      approve: "TERIMA",
      reflection: "Refleksi",
      approved: "DILULUSKAN",
      pending: "BELUM SELESAI",
      refer_back_approve_comments: "MERUJUK KEMBALI / TERIMA KOMEN",
      approve_comments: "TERIMA KOMEN",
    },
    create_message: {
      message: "Maklumat",
      all_staffs: "Semua Kakitangan",
      select_staff: "Pilih Pengguna",
      start_date: "Tarikh Mula",
      end_date: "Tarikh Tamat",
      create_new_message: "Maklumat Baru",
    },
    message_history: {
      start_date: "Tarikh Mula",
      end_date: "Tarikh Tamat",
      message: "Maklumat",
      actions: "Aktiviti",
      messages: "Mesej",
      Rows_per_page: "Halaman",
    },
    view_syllabus: {
      view_syllabus: "LIHAT KURIKULUM STANDARD",
      class_type: "Jenis Kelas",
      select_subject: "Pilihan Subjek",
      view: "lihat semua",
    },
    complain_form: {
      school_name: "Nama Sekolah",
      school_code: "Kod Sekolah",
      staff_name: "Nama Pengguna",
      phone_number: "Telefon Nombor",
      complain: "Mengeluh",
      send_complaint: "HANTAR ADUAN",
      submit_complaint: "SERAH ADUAN",
    },
    self_service: {
      staff_self_service: "LAYANAN DIRI STAF",
      personal_details: "Maklumat Peribadi",
      education_details: "Maklumat Pendidikan",
      name: "Nama",
      email: "Email",
      gender: "Jantina",
      user_type: "Jenis Pengguna",
      no_rs: "Nombor RS",
      ic_number: "No.Kad.Pengenalan",
      mobile_number: "No.Telefon",
      category: "Kategori",
      permanent_address: "Alamat Tetap",
      current_address: "Alamat Semasa",
      spouse_name: "Nama Pasangan",
      spouse_phone_number: "No.Telefon Pasangan",
      spouse_job_details: "Maklumat Pasangan",
      upload_photo: "Muat Turun Gambar",
      first_employment_joining_date: "Tarikh Lantikan",
      current_employment_joining_date: "Tarikh Lantikan di Sekolah Semasa",
      save: "Tambah",
      course: "Kursus",
      major: "utama",
      university: "Universiti",
      year: "tahun",
      actions: "Aktiviti",
      no_data_availble: "No data available",
      rows_per_page: "Halaman",
      add: "Tambah",
      joining_date: "Tarikh Lantikan",
    },
  },
  cn: {
    message: {
      header: "chinese Dashboard",
    },
    enroll_staff: {
      first_name: "名",
    },
  },
  tn: {
    chapter_list: {
      unit_name: "Unit Name",
      content_name: "Content Name",
      learning_standard: "Learning Standard",
      content_standard: "Content Standard",
    },
    message: {
      header: "Tamil Dashboard",
    },
    common: {
      end_date: " End Date",
      no_schedule: " No Schedule",
    },
    enroll_staff: {
      first_name: "முதல் பெயர்",
      last_name: "இறுதி பெயர்",
      contact_number: "தொடர்பு எண்",
      gender: "பாலினம்",
      religion: "மதம்",
      date_of_joining: "பணி நிமிர்த்த திகதி",
      user_type: "பயணர் வகை",
      race: "இனம்",
      date_of_birth: "பிறந்த திகதி",
      email: "மின்னஞ்சல்",
      category: "வகை",
      enroll_new_staff: "புதிய பயணர் பதிவு",
      reset: "மீட்டமைத்தல்",
      add: "சேர்த்தல்",
    },
    staff_list: {
      search: "தேடுக ",
      staff_list: "பயணர் பட்டியல்",
    },
    today_slot: {
      today_time_table: "இன்றைய நேர அட்டவணை",
    },
    class_schedule: {
      weekly_time_table: "வாராந்திர கால அட்டவணை",
      class_type: "வகுப்பு வகை",
      class_name: "வகுப்பு பெயர்",
    },
    view_slots: {
      time_slot: "ஒதுக்கப்பட்ட நேரம்",
      time_slot_details: "பாட நேர தகவல்",
      add: "சேர்த்தல்",
      action: "நடவடிக்கை",
      rows_per_page: "பக்கங்கள்",
      start: "தொடங்கும் நேரம்",
      end: "முடியுவடையும் நேரம்",
      active: "செயலில்",
    },
    time_slot: {
      add_time: "நேரம்",
      start: "தொடங்கும் நேரம்",
      end: "முடியுவடையும் நேரம்",
      save: "சேமி",
    },
    class_details: {
      class_details: "வகுப்பு விவரம்",
      class_type: "வகுப்பு வகை",
      class_name: "வகுப்பு பெயர்",
      no_of_students: "மாணவர் எண்ணிக்கை",
      add_class: "வகுப்பைச் சேர்த்தல்",
      action: "நடவடிக்கை",
      assign_subject: "பாட விபரம்",
      rows_per_page: "பக்கங்கள்",
      save: "சேமி",
      close: "மறைக்க",
    },
    subject_assign: {
      subject: "பாடம்",
      assignation_details: "பணி விவரங்கள்",
      assign_subject: "பாட விபரம்",
      add_new_subject: "புதிய பாடம் சேர்த்தல்",
      select_subject: "பாடம் தேர்ந்தெடுத்தல்",
      select_staff: "பயணர் விபரம்",
      class_type: "வகுப்பு வகை",
      class_name: "வகுப்பு பெயர்",
      no_of_students: "மாணவர் எண்ணிக்கை",
      action: "நடவடிக்கை",
      rows_per_page: "பக்கங்கள்",
      save: "சேமி",
      close: "மறைக்க",
      subject_name: "பாட பெயர்",
      end_date: "முடிவடையும் திகதி",
      add_time_table: "நேர அட்டவணையைச் சேர்க்கவும்",
      week_day: "வார நாள்",
      schedule: "அட்டவணை",
      assign: "விபரம்",
      staff_name: "பணியாளர் பெயர்",
      start_date: "தொடங்கும் திகதி",
      time_slot: "ஒதுக்கப்பட்ட நேரம்",
    },
    create_message: {
      message: "தகவல்",
      all_staffs: "அனைவருக்கும்",
      select_staff: "பணியாளரை தேர்ந்தெடுக்கவும்",
      start_date: "தொடக்க திகதி",
      end_date: "முடிவடையும் திகதி",
      create_new_message: "உருவாக்குதல்",
    },
    lesson_update: {
      class_type: "வகுப்பு வகை",
      self_assign: "சுய பணிகள்",
      lesson_update: "பாடம் புதுப்பிப்பு",
      class_name: "வகுப்பு பெயர்",
      time_slot: "ஒதுக்கப்பட்ட நேரம்",
      start_date: "தொடக்க திகதி",
      end_date: "முடிவடையும் திகதி",
      actions: "நடவடிக்கை",
      name: "பெயர்",
      date: "திகதி",
      subject: "பாடம்",
      day: "நாள்",
      status: "நிலை",
      search: "தேடுக",
      first_name: "முதல் பெயர்",
      to_date: "திகதிவரை",
      from_date: "திகதியிலிருந்து",
      reset_filter: "வடிகட்டியை மீட்டமைக்கவும்",

      download_report: "அறிக்கை பதிவிறக்கம்",
      unit: "தலைப்பு",
      content: "உள்ளடக்கம்",
      learning_standard: "கற்றல் திரன்",
      content_standard: "உள்ளடக்க திரன்",
      activity: "செயல்பாடு",
      objective: "புறநிலை",
      save: "சேமி",
      update: "மேம்படு",
      view: "பார்க்க",
      update_reflection: "பிரதிபலிப்பைப் புதுப்பிக்கவும்",
      refer_back: "மீண்டும் பார்க்கவும்",
      referred_back: "மீண்டும் குறிப்பிடப்படுகிறது",
      approve: "ஒப்புதல்",
      reflection: "பிரதிபலிப்பு",
      approved: "அங்கீகரிக்கப்பட்டது",
      close: "மறைக்க",
      refer_back_approve_comments: "மீண்டும் பார்க்கவும்/ஒப்புதல் கருத்துக்கள்",
      approve_comments: "ஒப்புதல் கருத்துக்கள்",
      pending: "நிலுவையில் உள்ளது",
    },
    message_history: {
      start_date: "தொடக்க திகதி",
      end_date: "முடிவடையும் திகதி",
      message: "தகவல்",
      actions: "நடவடிக்கை",
      messages: " தகவல்கள் ",
      rows_per_page: "பக்கங்கள்",
    },
    view_syllabus: {
      view_syllabus: "கலைத்திட்ட்த்தைப் பார்க்க",
      class_type: "வகுப்பு வகை",
      select_subject: "பாடம் தேர்ந்தெடுத்தல்",
      view: "பார்வையிடுக",
    },
    complain_form: {
      school_name: "பள்ளி பெயர்",
      school_code: "பள்ளி குறியீடு",
      staff_name: "பணியாளர் பெயர்",
      phone_number: "தொலைபேசி எண்",
      complain: "புகார்",
      submit_complaint: "புகார் அளித்தல்",
    },
    self_service: {
      staff_self_service: "பணியாளர்கள் சுய சேவை",
      name: "பெயர்",
      email: "மின்னஞ்சல்",
      gender: " பாலினம் ",
      user_type: "பயணர் வகை",
      no_rs: "R/S எண்",
      ic_number: "அடையாள அட்டை எண்",
      mobile_number: "தொடர்பு எண்",
      category: "வகை",
      permanent_address: "நிரந்தர முகவரி",
      current_address: "தற்போதைய முகவரி",
      spouse_name: "கணவன்/மனைவியின் பெயர்",
      spouse_phone_number: "கணவன்/மனைவியின் தொலைபேசி எண்",
      spouse_job_details: "கணவன்/மனைவியின் பணி விபரம்",
      upload_photo: "புகைப்பட பதிவேற்றல்",
      first_employment_joining_date: "முதல் பணிநிமிர்த்த திகதி",
      current_employment_joining_date: "தற்போதைய பணிநிமிர்த்த திகதி",
      save: "சேமி",
      employment_details: "பணி விவரம்",
      personal_details: "சுய விபரம்",
      education_details: "கல்வி விபரம்",
      course: "படிப்புகள்",
      major: "முக்கிய பாடம்",
      university: "பல்கலைக்கழகம்",
      year: "ஆண்டு",
      actions: "நடவடிக்கை",
      no_data_availble: "No data available",
      rows_per_page: "பக்கங்கள்",
      add: "சேர்த்தல்",
      joining_date: "பணிநிமிர்த்த திகதி",
    },
  },
};

module.exports = lang;
